import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import { hasGameWithCode } from 'store/modules/categoryGames/utils'

const getCategoryGames = (state: ReduxState) => state.categoryGames

const getFavoriteGames = (state: ReduxState) => getCategoryGames(state).favoriteGames
export const getRecentlyPlayedGames = (state: ReduxState) => getCategoryGames(state).recentlyPlayedGames
export const getRecommendedGames = (state: ReduxState) => getCategoryGames(state).recommendedGames

export const getFavoriteGamesSelector = createSelector(getFavoriteGames, (favoriteGames) => favoriteGames)

export const isCategoryGamesLoadedSelector = createSelector(
  getCategoryGames,
  (favoriteGames) => favoriteGames.isSuccess
)

export const isFavoriteGameSelector = createSelector(
  getFavoriteGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const getRecentlyPlayedGamesSelector = createSelector(
  getRecentlyPlayedGames,
  (recentlyPlayedGames) => recentlyPlayedGames
)

export const isRecentlyPlayedGames = createSelector(
  getRecentlyPlayedGamesSelector,
  (_: ReduxState, gameCode: string) => gameCode,
  (games, gameCode) => hasGameWithCode(games, gameCode)
)

export const getRecommendedGamesSelector = createSelector(getRecommendedGames, (recommendedGames) => recommendedGames)
