import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FreeContribution } from '@patrianna/shared-patrianna-types/store/JackpotsModule'
import type {
  AccountJackpotInfo,
  Contributions,
  JackpotInfo,
  JackpotPrize,
  JackpotsState,
} from 'store/types/JackpotsModule'
import { mergeJackpotResults } from 'store/modules/jackpots/utils'

const SC_CONTRIBUTION_COEFFICIENT = 10

const initialState: JackpotsState = {
  accountJackpotInfo: {
    info: null,
    contributions: null,
    freeContributions: [],
    wins: [],
    loaded: false,
  },
  jackpotInfo: [
    {
      currency: 'GC',
      jackpots: [
        {
          code: 'mini',
          amount: 0,
        },
        {
          code: 'minor',
          amount: 0,
        },
        {
          code: 'major',
          amount: 0,
        },
        {
          code: 'grand',
          amount: 0,
        },
      ],
    },
    {
      currency: 'SC',
      jackpots: [
        {
          code: 'mini',
          amount: 0,
        },
        {
          code: 'minor',
          amount: 0,
        },
        {
          code: 'major',
          amount: 0,
        },
        {
          code: 'grand',
          amount: 0,
        },
      ],
    },
  ],
  jackpotPrize: [],
  contributions: [],
  updateInterval: 1,
}

export const jackpotsSlice = createSlice({
  name: 'jackpots',
  initialState,
  reducers: {
    setAccountJackpotInfo: (state, action: PayloadAction<AccountJackpotInfo>) => {
      state.accountJackpotInfo.info = action.payload.info
      state.accountJackpotInfo.contributions = action.payload.contributions
      state.accountJackpotInfo.freeContributions = action.payload.freeContributions.map((el) => ({
        ...el,
        amount: el.amount * SC_CONTRIBUTION_COEFFICIENT,
        usedAmount: el.usedAmount * SC_CONTRIBUTION_COEFFICIENT,
      }))
      state.accountJackpotInfo.wins = action.payload.wins
      state.accountJackpotInfo.loaded = true
    },
    setJackpotTotals: (state, action: PayloadAction<JackpotInfo[]>) => {
      state.jackpotInfo = action.payload
    },
    setJackpotPrize: (state, action: PayloadAction<JackpotPrize>) => {
      state.jackpotPrize.push(action.payload)
    },
    setContibutionsJackpot: (state, action: PayloadAction<Contributions>) => {
      state.contributions = action.payload
    },
    setJackpotUpdateInterval: (state, action: PayloadAction<number>) => {
      state.updateInterval = action.payload
    },
    patchJackpotTotals: (state, action: PayloadAction<JackpotInfo[]>) => {
      state.jackpotInfo = mergeJackpotResults(state.jackpotInfo, action.payload)
    },
    updateFreeContributionsBalance: (state, action: PayloadAction<FreeContribution[]>) => {
      state.accountJackpotInfo.freeContributions = action.payload.map((el) => ({
        ...el,
        amount: el.amount * SC_CONTRIBUTION_COEFFICIENT,
        usedAmount: el.usedAmount * SC_CONTRIBUTION_COEFFICIENT,
      }))
    },
    addNewFreeContributionReward: (state, action: PayloadAction<FreeContribution>) => {
      const freeContribution = action.payload

      state.accountJackpotInfo.freeContributions.push({
        ...freeContribution,
        amount: freeContribution.amount * SC_CONTRIBUTION_COEFFICIENT,
        usedAmount: freeContribution.usedAmount * SC_CONTRIBUTION_COEFFICIENT,
      })
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof jackpotsSlice.actions>>
export const { actions } = jackpotsSlice
