import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'

const snackbarVisibility = (state: ReduxState) => state.snackbar.visible
const getSnackbarVariant = (state: ReduxState) => state.snackbar.variant
const getSnackbarMessage = (state: ReduxState) => state.snackbar.message
const getSnackbarActionButton = (state: ReduxState) => state.snackbar.action
const getSnackbarAutoHide = (state: ReduxState) => state.snackbar.autoHide
const getSnackbarMessageAlign = (state: ReduxState) => state.snackbar.messageAlign
const getSnackbarComponentProps = (state: ReduxState) => state.snackbar.componentProps
const getPositionVertical = (state: ReduxState) => state.snackbar.positionVertical
const getPositionHorizontal = (state: ReduxState) => state.snackbar.positionHorizontal

export const getSnackbarVisibilitySelector = createSelector(snackbarVisibility, (visible) => visible)

export const getSnackbarVariantSelector = createSelector(getSnackbarVariant, (variant) => variant)

export const getSnackbarMessageSelector = createSelector(getSnackbarMessage, (message) => message)
export const getSnackbarActionButtonSelector = createSelector(getSnackbarActionButton, (actionButton) => actionButton)
export const getSnackbarMessageAlignSelector = createSelector(getSnackbarMessageAlign, (align) => align)
export const getPositionVerticalSelector = createSelector(getPositionVertical, (vertical) => vertical)
export const getPositionHorizontalSelector = createSelector(getPositionHorizontal, (horizontal) => horizontal)
export const getSnackbarComponentPropsSelector = createSelector(getSnackbarComponentProps, (align) => align)

export const getSnackbarAutoHideSelector = createSelector(getSnackbarAutoHide, (autoHide) => autoHide)
