import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { Currencies, Currency } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { CurrenciesState } from 'store/types/CurrenciesModule'
import { trackEvent } from 'config/analytic'

const initialState: CurrenciesState = {
  allCurrencies: [],
  activeCurrencyId: 'GC',
  activeModalCurrencyId: null,
}

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    initCurrencies: (state, action: PayloadAction<{ currencies: Array<Currency> }>) => {
      state.allCurrencies = action.payload.currencies.map((el) => ({ ...el }))
    },
    setActiveCurrency: (state, action: PayloadAction<{ activeCurrencyId: Currencies }>) => {
      trackEvent('switch_currency', { category: 'switch currency', label: action.payload.activeCurrencyId })
      state.activeCurrencyId = action.payload.activeCurrencyId
    },

    // @TODO create middleware for this action
    toggleActiveCurrency: (state) => {
      state.activeCurrencyId = state.activeCurrencyId === 'GC' ? 'SC' : 'GC'
    },

    setActiveModalCurrency: (state, action: PayloadAction<{ currencyId: Currencies }>) => {
      state.activeModalCurrencyId = action.payload.currencyId
    },

    updateCurrenciesBalance: (state, action: PayloadAction<{ currency: Array<Currency> }>) => {
      state.allCurrencies = state.allCurrencies.map((currency) => {
        const updatedCurrency = action.payload.currency.find((i) => i.currency === currency.currency)

        return updatedCurrency || currency
      })
    },
    clearCurrency: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof currenciesSlice.actions>>
export const { actions } = currenciesSlice
