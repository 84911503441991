import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import { getBalanceByIDSelector } from 'store/modules/currencies/selectors'
import { isPast, isWednesday } from 'date-fns'
import {
  getItemFormLocalStorageSelector,
  OPT_IN_NOW_DIALOG_SHOWN,
  SHOWN_SC_FREE_CONTRIBUTIONS_CODES,
} from 'utils/localStorage'
import { jackpotDefaultValues, jackpotSortOrder } from 'utils/jackpot'
import type { Currency } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import type { FreeContribution, JackpotTotalsCode } from '@patrianna/shared-patrianna-types/store/JackpotsModule'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'

const getJackpotsRoot = (state: ReduxState) => state.jackpots

export const getJackpotGoldInfoSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.jackpotInfo?.find((it) => it.currency === 'GC')
})

export const getJackpotSweepstakeInfoSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.jackpotInfo?.find((it) => it.currency === 'SC')
})

export const getSweepstakeAccountInfoSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.accountJackpotInfo.info?.find((it) => it.currency === 'SC')
})

export const getSweepstakeJackpotOptInSelector = createSelector(
  getSweepstakeAccountInfoSelector,
  (sweepstakeAccountInfo) => {
    return sweepstakeAccountInfo?.isOptedIn
  }
)

export const getSweepstakeMinContibuteAmountSelector = createSelector(
  getJackpotSweepstakeInfoSelector,
  (sweepstakeJackpotInfo) => {
    return sweepstakeJackpotInfo?.minAmount
  }
)

export const getGoldMinContibuteAmountSelector = createSelector(getJackpotGoldInfoSelector, (goldJackpotInfo) => {
  return goldJackpotInfo?.minAmount
})

export const getSweepstakeJackpotTotalsSelector = createSelector(
  getJackpotSweepstakeInfoSelector,
  (sweepstakeJackpotInfo) => {
    return Array.from(sweepstakeJackpotInfo?.jackpots || jackpotDefaultValues)
      .map((item) => ({ ...item, currency: 'SC' }))
      .sort((a, b) => jackpotSortOrder.indexOf(a.code) - jackpotSortOrder.indexOf(b.code))
  }
)

export const getGoldJackpotTotalsSelector = createSelector(getJackpotGoldInfoSelector, (goldJackpotInfo) => {
  return Array.from(goldJackpotInfo?.jackpots || jackpotDefaultValues)
    .map((item) => ({ ...item, currency: 'GC' }))
    .sort((a, b) => jackpotSortOrder.indexOf(a.code) - jackpotSortOrder.indexOf(b.code))
})

export const isJackpotsEnabledSelector = createSelector(
  getJackpotGoldInfoSelector,
  getJackpotSweepstakeInfoSelector,
  (jackpotsGoldInfo, jackpotsScInfo) => {
    return Boolean(jackpotsGoldInfo?.jackpots.length && jackpotsScInfo?.jackpots.length)
  }
)

export const isAccountJackpotInfoLoaded = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.accountJackpotInfo?.loaded
})

export const getContributionsJackpotSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.contributions
})

export const getUpdateInterval = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.updateInterval
})

export const isOptInNowAvailableSelector = createSelector(
  getSweepstakeJackpotOptInSelector,
  (state) => getBalanceByIDSelector(state, 'SC'),
  (state) => getItemFormLocalStorageSelector(state, OPT_IN_NOW_DIALOG_SHOWN, false),
  (isOptIn, balance, optInTime) => {
    const date = new Intl.DateTimeFormat('en-US').format(new Date())
    const isFirstLaunchOrScheduledDay = isWednesday(new Date()) || !optInTime

    if (date === optInTime) {
      return false
    }

    return !isOptIn && (balance as Currency).amount > 0.1 && isFirstLaunchOrScheduledDay
  }
)

export const getSweepstakeFreeContributionsSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.accountJackpotInfo.freeContributions?.filter((i) => i.currency === 'SC') || []
})

export const getCurrentFreeContributionSelector = createSelector(
  getSweepstakeFreeContributionsSelector,
  (freeContributions) => freeContributions.find((i) => !isPast(i.expireAt)) || null
)

export const getAvailableCurrentFreeContributionAmountSelector = createSelector(
  getCurrentFreeContributionSelector,
  (freeContribution) => {
    return freeContribution?.amount - freeContribution?.usedAmount || 0
  }
)

export const getUnseenFreeContributionRewardSelector = createSelector(
  getSweepstakeFreeContributionsSelector,
  (state) => getItemFormLocalStorageSelector(state, SHOWN_SC_FREE_CONTRIBUTIONS_CODES, false),
  (freeContributions, shownCodes) => {
    // TODO: Replace it with findLast method after TS version update
    const unseenMostRecentReward = (freeContributions as FreeContribution[])
      .slice()
      .reverse()
      .find((i) => !isPast(i.expireAt) && !(shownCodes as FreeContribution['code'][])?.includes(i.code))

    return unseenMostRecentReward || null
  }
)

export const getAvailableUnseenFreeContributionRewardAmountSelector = createSelector(
  getUnseenFreeContributionRewardSelector,
  (freeContribution) => {
    return freeContribution?.amount - freeContribution?.usedAmount || 0
  }
)

export const isNewFreeContributionRewardAvailableSelector = createSelector(
  getSweepstakeFreeContributionsSelector,
  (state) => getItemFormLocalStorageSelector(state, SHOWN_SC_FREE_CONTRIBUTIONS_CODES, false),
  (freeContributions, shownCodes) => {
    const shownCodesSet = new Set(shownCodes as FreeContribution['code'][])

    return (freeContributions as FreeContribution[]).some((i) => !shownCodesSet.has(i.code))
  }
)

export const getWinsJackpotSelector = createSelector(getJackpotsRoot, (jackpots) => {
  return jackpots.accountJackpotInfo.wins.reduce(
    (acc, { currency, type, winsCount }) => {
      acc[currency] = {
        ...(acc[currency] || {}),
        [type]: winsCount,
      }

      return acc
    },
    {} as {
      [currency in Currencies]: {
        [type in JackpotTotalsCode]?: number
      }
    }
  )
})
