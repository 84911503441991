import type { JackpotInfo } from 'store/types'

export const mergeJackpotResults = (currentJackpots: JackpotInfo[], jackpotInfo: JackpotInfo[]) => {
  return currentJackpots.map((oldJackpotInfo) => {
    const updatedJackpotInfo = jackpotInfo.find((currentJackpotInfo) => {
      return currentJackpotInfo.currency === oldJackpotInfo.currency
    })
    if (updatedJackpotInfo) {
      oldJackpotInfo.jackpots = oldJackpotInfo.jackpots.map((oldJackpot) => {
        const updatedJackpot = updatedJackpotInfo.jackpots.find((jackpot) => {
          return oldJackpot.code === jackpot.code
        })

        return updatedJackpot || oldJackpot
      })
    }

    return oldJackpotInfo
  })
}
