import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getGamesByCategoryCode } from 'utils/loadGameInfoGetInitialProps'
import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { TypedThunk } from 'src/store/types'

export const addFavoriteGameToStore = createAction<GameProductShort>('addFavoriteGameToStore')
export const removeFavoriteGameFromStore = createAction<string>('removeFavoriteGameFromStore')

export const addFavoriteGame =
  (game: GameProductShort): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    dispatch(addFavoriteGameToStore(game))

    return gateway.emit<unknown>({
      type: 'AddAccountFavouriteGameRequest',
      gameCode: game.code,
    })
  }

export const removeFavoriteGame =
  (gameCode: string): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    dispatch(removeFavoriteGameFromStore(gameCode))

    return gateway.emit<unknown>({
      type: 'RemoveAccountFavouriteGameRequest',
      gameCode,
    })
  }

export const fetchCategoryGames = createAsyncThunk('games/fetchCategoryGames', async () => {
  const response = await getGamesByCategoryCode([
    { productCategoryCode: 'favourite' },
    { productCategoryCode: 'last_played' },
    { productCategoryCode: 'recommend' },
  ])

  return response.categories
})
