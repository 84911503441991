import { captureMessage } from '@sentry/nextjs'
import type { CategoryCode } from '@patrianna/shared-patrianna-types/services/productCategories'
import type { GameProduct, SwimlaneCategory } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { GetProductLayoutResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { isBrowser } from '@patrianna/shared-utils/helpers'
import ROUTES from 'temp/routes.json'
import type { PragmaticJackpot } from 'src/store/modules/pragmaticJackpots/types'
import { serviceDependencies } from 'src/services'

export async function getAllGames(cookies?: string, headers?: Record<string, string>): Promise<GameProduct[]> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-all-products?brandName=${process.env.BRAND_NAME}`

  try {
    const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
    const result = await response.json()

    return result.products
  } catch (error) {
    console.log('getAllGames error', url, error)
    captureMessage(`getAllGames url error ${JSON.stringify(error)}`)

    return Promise.reject(error)
  }
}

export async function getAllCategories(
  cookies?: string,
  headers?: Record<string, string>
): Promise<SwimlaneCategory[]> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`
  const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies }, headers)
  const result = await response.json()

  return result.categories
}

export async function getPragmaticJackpots(currency: 'SC' | 'GC'): Promise<PragmaticJackpot[]> {
  try {
    const response = await serviceDependencies.fetch(`${process.env.PRAGMATIC_JACKPOTS}?currency=${currency}`, null, {
      method: 'get',
    })
    const result = await response.json()

    return result.jackpots || []
  } catch (error) {
    return []
  }
}

export async function getCategoryByRoute(categoryRoute: string, cookies?: string): Promise<SwimlaneCategory> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-layout?brandName=${process.env.BRAND_NAME}`
  const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
  const result = await response.json()
  const category = result?.categories.find(
    (i: SwimlaneCategory) => i.route === `${ROUTES.GAMES_SLOTS}/${categoryRoute ? `${categoryRoute}/` : ''}`
  )

  return category
}

export async function getGameByCode(code: string): Promise<GameProduct> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&code=${code}`
  const response = await serviceDependencies.fetch(url, null, { method: 'get' })
  const game = await response.json()

  return game
}

export async function getGameByRoute(route: string, cookies?: string): Promise<GameProduct> {
  const path = typeof window === 'undefined' ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/get-product?brandName=${process.env.BRAND_NAME}&route=${route}`
  const response = await serviceDependencies.fetch(url, null, { method: 'get', cookies })
  const game = await response.json()

  return game
}

const dict = {
  onl: 'onlinelotto',
  pulsz: 'pulsz',
  pulszbingo: 'pulszbingo',
  mcluck: 'mcluck',
}

const metaTitleDict = {
  slots: 'Video Slots',
  video_slots: 'Video Slots',
  classic_slots: 'Video Slots',
  scratch_card: 'Video Slots',
  table: 'Casino Games',
  table_games: 'Casino Games',
  casino: 'Casino Games',
  casino_games: 'Casino Games',
  blackjack: 'Blackjack',
  video_poker: 'Casino Games',
  roulette: 'Roulette',
  baccarat: 'Baccarat',
  baccarat_new: 'Baccarat',
  bingo: 'Bingo',
}

const metaDescriptionDict = {
  slots: 'Video Slots',
  video_slots: 'Video Slots',
  classic_slots: 'Video Slots',
  scratch_card: 'Video Slots',
  table: 'Casino Games',
  table_games: 'Casino Games',
  casino: 'Casino Games',
  casino_games: 'Casino Games',
  blackjack: 'Blackjack',
  video_poker: 'Casino Games',
  roulette: 'Roulette',
  baccarat: 'Baccarat',
  baccarat_new: 'Baccarat',
  bingo: 'Bingo',
}

export function generateMetaData(gameProduct: GameProduct) {
  const commonObj = {
    title: `${gameProduct.title} | Play ${metaTitleDict[gameProduct.type as keyof typeof metaTitleDict]} at ${
      dict[process.env.BRAND_NAME as keyof typeof dict]
    }.com`,
    meta: `Play the ${gameProduct.title} ${
      metaDescriptionDict[gameProduct.type as keyof typeof metaDescriptionDict]
    } online at ${dict[process.env.BRAND_NAME as keyof typeof dict]}.com`,
  }

  return commonObj
}

export async function getGamesByCategoryCode(
  placements: {
    productCategoryCode: CategoryCode
    gameId?: string
  }[],
  cookies?: string,
  headers?: Record<string, string>
): Promise<GetProductLayoutResponse> {
  const path = !isBrowser() ? process.env.SERVER_FETCH_URL : process.env.GATEWAY
  const url = `${path}/v1/product-category?brandName=${process.env.BRAND_NAME}`
  const body = { placements }

  const data = await serviceDependencies.fetch(url, body, { cookies }, headers)

  return data.json()
}
